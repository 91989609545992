import { getLocalStorageItem } from './helper';

export const MAX_DESCRIPTION_LENGTH = 290;
export const MAX_DESCRIPTION_LENGTH1 = 400;

export const AGE_RANGE_MENU = [
  { name: 'Select', value: 'select' },
  { name: '18-24', value: '18-24' },
  { name: '25-34', value: '25-34' },
  { name: '35-44', value: '35-44' },
  { name: '45-54', value: '45-54' },
  { name: '55-64', value: '55-64' },
  { name: '65+', value: '65+' },
  { name: 'Prefer not to say', value: 'Prefer not to say' },
];

export const GENDER_MENU = [
  { name: 'Select', value: 'select' },
  { name: 'Male', value: 'Male' },
  { name: 'Female', value: 'Female' },
  { name: 'Intersex', value: 'Intersex' },
  { name: 'Non-binary', value: 'Non-binary' },
  { name: 'Prefer not to say', value: 'Prefer not to say' },
  { name: 'Prefer to self-describe', value: 'Prefer to self-describe' },
];

export const SALARY_RANGE_MENU = [
  { name: 'Select', value: '0' },
  { name: '£0 - £15,000', value: '£0 - £15,000' },
  { name: '£15,001 - £25,000', value: '£15,001 - £25,000' },
  { name: '£25,001 - £35,000', value: '£25,001 - £35,000' },
  { name: '£35,001 - £50,000', value: '£35,001 - £50,000' },
  { name: '£50,001 - £75,000', value: '£50,001 - £75,000' },
  { name: '£75,001 - £100,000', value: '£75,001 - £100,000' },
  { name: '£100,001 - £150,000', value: '£100,001 - £150,000' },
  { name: '£150,001 and above', value: '£150,001 and above' },
  { name: 'Prefer not to say', value: 'Prefer not to say' },
];

export const ETHNICITY_MENU = [
  { name: 'Select', value: 'select' },
  {
    name: 'White – British',
    value: 'White – British',
  },
  {
    name: 'White – Irish',
    value: 'White – Irish',
  },
  {
    name: 'White – Any other White background',
    value: 'White – Any other White background',
  },
  {
    name: 'Mixed – White and Black Caribbean',
    value: 'Mixed – White and Black Caribbean',
  },
  {
    name: 'Mixed – White and Black African',
    value: 'Mixed – White and Black African',
  },
  {
    name: 'Mixed – White and Asian',
    value: 'Mixed – White and Asian',
  },
  {
    name: 'Mixed – Any other Mixed background',
    value: 'Mixed – Any other Mixed background',
  },
  {
    name: 'Asian or Asian British – Indian',
    value: 'Asian or Asian British – Indian',
  },
  {
    name: 'Asian or Asian British – Pakistani',
    value: 'Asian or Asian British – Pakistani',
  },
  {
    name: 'Asian or Asian British – Bangladeshi',
    value: 'Asian or Asian British – Bangladeshi',
  },
  {
    name: 'Asian or Asian British – Any other Asian background',
    value: 'Asian or Asian British – Any other Asian background',
  },
  {
    name: 'Black or Black British – Caribbean',
    value: 'Black or Black British – Caribbean',
  },
  {
    name: 'Black or Black British – African',
    value: 'Black or Black British – African',
  },
  {
    name: 'Black or Black British – Any other Black background',
    value: 'Black or Black British – Any other Black background',
  },
  {
    name: 'Other ethnic groups – Chinese',
    value: 'Other ethnic groups – Chinese',
  },
  {
    name: 'Other ethnic groups – Any other ethnic group',
    value: 'Other ethnic groups – Any other ethnic group',
  },
  { name: 'Prefer not to say', value: 'Prefer not to say' },
  { name: 'Prefer to self-describe', value: 'Prefer to self-describe' },
];

export const DISABILITY_MENU = [
  { name: 'Select', value: 'select' },
  { name: 'Yes', value: 'Yes' },
  { name: 'No', value: 'No' },
  { name: 'Prefer not to say', value: 'Prefer not to say' },
];

export const SEX_SAME_FROM_BIRTH = [
  { name: 'Select', value: 'select' },
  { name: 'Yes', value: 'Yes' },
  { name: 'No', value: 'No' },
  { name: 'Prefer not to say', value: 'Prefer not to say' },
];

export const SEXTUALITY = [
  { name: 'Select', value: 'select' },
  { name: 'Asexual', value: 'Asexual' },
  { name: 'Bisexual', value: 'Bisexual' },
  { name: 'Gay/Lesbian', value: 'Gay/Lesbian' },
  { name: 'Heterosexual/Straight', value: 'Heterosexual/Straight' },
  { name: 'Pansexual', value: 'Pansexual' },
  { name: 'Prefer not to say', value: 'Prefer not to say' },
  { name: 'Prefer to self-describe', value: 'Prefer to self-describe' },
];
export const RELIGION_OR_BELIEF = [
  { name: 'Select', value: 'select' },
  { name: 'Atheism', value: 'Atheism' },
  { name: 'Agnostic', value: 'Agnostic' },
  { name: 'Buddhist', value: 'Buddhist' },
  {
    name: 'Christianity (Catholic, Baptist, Methodist, Anglican (CoE), etc.)',
    value: 'Christianity (Catholic, Baptist, Methodist, Anglican (CoE), etc.)',
  },
  { name: 'Hinduism', value: 'Hinduism' },
  { name: 'Islam', value: 'Islam' },
  { name: 'No Relision', value: 'No Relision' },
  { name: 'Sikhism', value: 'Sikhism' },
  { name: 'Judaism', value: 'Judaism' },
  { name: 'Prefer not to say', value: 'Prefer not to say' },
  { name: 'Not listed', value: 'Not listed' },
];

export const RELATIONSHIP_STATUS = [
  { name: 'Select', value: 'select' },
  { name: 'Civil partnership', value: 'Civil partnership' },
  { name: 'Cohabiting', value: 'Cohabiting' },
  { name: 'Divorced', value: 'Divorced' },
  { name: 'Married', value: 'Married' },
  { name: 'Separated', value: 'Separated' },
  { name: 'Single', value: 'Single' },
  { name: 'Widowed', value: 'Widowed' },
  { name: 'Prefer not to say', value: 'Prefer not to say' },
  { name: 'Prefer to self-describe', value: 'Prefer to self-describe' },
];

export const MILITARY_VETERAN = [
  { name: 'Select', value: 'select' },
  { name: 'Army', value: 'Army' },
  { name: 'Navy', value: 'Navy' },
  { name: 'Air Force', value: 'Air Force' },
];

export const REGISTERED_AS = [
  { name: 'Select', value: 'select' },
  { name: 'Business', value: 'Business' },
  { name: 'Individual', value: 'Individual' },
];

export const IMAGES_ARR = [
  {
    id: 1,
    path: 'https://staging-media-app.hexitime.com/content-banners/Banner1.jpg',
    current: true,
  },
  {
    id: 2,
    path: 'https://staging-media-app.hexitime.com/content-banners/Banner2.jpg',
    current: false,
  },
  {
    id: 3,
    path: 'https://staging-media-app.hexitime.com/content-banners/Banner3.jpg',
    current: false,
  },
  {
    id: 4,
    path: 'https://staging-media-app.hexitime.com/content-banners/Banner4.jpg',
    current: false,
  },
  {
    id: 5,
    path: 'https://staging-media-app.hexitime.com/content-banners/Banner5.jpg',
    current: false,
  },
  {
    id: 6,
    path: 'https://staging-media-app.hexitime.com/content-banners/Banner6.jpg',
    current: false,
  },
  {
    id: 7,
    path: 'https://staging-media-app.hexitime.com/content-banners/Banner7.jpg',
    current: false,
  },
  {
    id: 8,
    path: 'https://staging-media-app.hexitime.com/content-banners/Banner8.jpg',
    current: false,
  },
  {
    id: 9,
    path: 'https://staging-media-app.hexitime.com/content-banners/Banner9.jpg',
    current: false,
  },
  {
    id: 10,
    path: 'https://staging-media-app.hexitime.com/content-banners/Banner10.jpg',
    current: false,
  },
  {
    id: 11,
    path: 'https://staging-media-app.hexitime.com/content-banners/banner11.jpeg',
    current: false,
  },
  {
    id: 12,
    path: 'https://staging-media-app.hexitime.com/content-banners/banner12.jpeg',
    current: false,
  },
];

export const IMAGE_FILE_TYPES = ['jpg', 'jpeg', 'png'];

export const PASSWORD_VALIDATION = {
  minLength: 8,
  minLowerCase: 1,
  minUpperCase: 1,
  minNumbers: 1,
  minSymbols: 0, // Set minSymbols to 0 to remove special character validation
  returnScore: false,
};

export const AVATAR_COLORS_ARRAY = [
  { textColor: '#177E9C', bgColor: '#E3F7FC' },
  { textColor: '#177E9C', bgColor: '#BFF3FF' },
  { textColor: '#ffffff', bgColor: '#53919E' },
  { textColor: '#ffffff', bgColor: '#244850' },
  { textColor: '#177E9C', bgColor: '#AFEFE4' },
  { textColor: '#ffffff', bgColor: '#366C5C' },
  { textColor: '#ffffff', bgColor: '#245043' },
];

export const CREDIT_HISTORY_COLUMNS = [
  {
    title: 'Credit Balance',
    key: 'credit_balance',
    sortable: true,
    type: 'text',
    align: 'center',
    sortKey: 'credit_balance',
  },
  {
    title: 'Given To',
    key: 'given_to',
    sortable: true,
    type: 'text',
    align: 'center',
    sortKey: 'to_user',
  },
  {
    title: 'Received From',
    key: 'received_from',
    sortable: true,
    type: 'text',
    align: 'center',
    sortKey: 'from_user',
  },
  {
    title: 'Date',
    key: 'created_at',
    sortable: true,
    type: 'date',
    align: 'center',
    sortKey: 'date',
  },
  {
    title: 'Remark',
    key: 'remark',
    sortable: false,
    type: 'text',
    align: 'center',
  },
];

export const logo = {
  logo: localStorage.getItem('logo'),
};

export const testCountrySelect = [
  {
    groupLabel: 'Select',
    items: ['select'],
  },
  {
    groupLabel: 'Afghanistan',
    items: ['Afghanistan'],
  },
  {
    groupLabel: 'Åland Islands',
    items: ['Åland Islands'],
  },
  {
    groupLabel: 'Albania',
    items: ['Albania'],
  },
  {
    groupLabel: 'Algeria',
    items: ['Algeria'],
  },
  {
    groupLabel: 'American Samoa',
    items: ['American Samoa'],
  },
  {
    groupLabel: 'AndorrA',
    items: ['AndorrA'],
  },
  {
    groupLabel: 'Angola',
    items: ['Angola'],
  },
  {
    groupLabel: 'Anguilla',
    items: ['Anguilla'],
  },
  {
    groupLabel: 'Antarctica',
    items: ['Antarctica'],
  },
  {
    groupLabel: 'Antigua and Barbuda',
    items: ['Antigua and Barbuda'],
  },
  {
    groupLabel: 'Argentina',
    items: ['Argentina'],
  },
  {
    groupLabel: 'Armenia',
    items: ['Armenia'],
  },
  {
    groupLabel: 'Aruba',
    items: ['Aruba'],
  },
  {
    groupLabel: 'Australia',
    items: ['Australia'],
  },
  {
    groupLabel: 'Austria',
    items: ['Austria'],
  },
  {
    groupLabel: 'Azerbaijan',
    items: ['Azerbaijan'],
  },
  {
    groupLabel: 'Bahamas',
    items: ['Bahamas'],
  },
  {
    groupLabel: 'Bahrain',
    items: ['Bahrain'],
  },
  {
    groupLabel: 'Bangladesh',
    items: ['Bangladesh'],
  },
  {
    groupLabel: 'Barbados',
    items: ['Barbados'],
  },
  {
    groupLabel: 'Belarus',
    items: ['Belarus'],
  },
  {
    groupLabel: 'Belgium',
    items: ['Belgium'],
  },
  {
    groupLabel: 'Belize',
    items: ['Belize'],
  },
  {
    groupLabel: 'Benin',
    items: ['Benin'],
  },
  {
    groupLabel: 'Bermuda',
    items: ['Bermuda'],
  },
  {
    groupLabel: 'Bhutan',
    items: ['Bhutan'],
  },
  {
    groupLabel: 'Bolivia',
    items: ['Bolivia'],
  },
  {
    groupLabel: 'Bosnia and Herzegovina',
    items: ['Bosnia and Herzegovina'],
  },
  {
    groupLabel: 'Botswana',
    items: ['Botswana'],
  },
  {
    groupLabel: 'Bouvet Island',
    items: ['Bouvet Island'],
  },
  {
    groupLabel: 'Brazil',
    items: ['Brazil'],
  },
  {
    groupLabel: 'British Indian Ocean Territory',
    items: ['British Indian Ocean Territory'],
  },
  {
    groupLabel: 'Brunei Darussalam',
    items: ['Brunei Darussalam'],
  },
  {
    groupLabel: 'Bulgaria',
    items: ['Bulgaria'],
  },
  {
    groupLabel: 'Burkina Faso',
    items: ['Burkina Faso'],
  },
  {
    groupLabel: 'Burundi',
    items: ['Burundi'],
  },
  {
    groupLabel: 'Cambodia',
    items: ['Cambodia'],
  },
  {
    groupLabel: 'Cameroon',
    items: ['Cameroon'],
  },
  {
    groupLabel: 'Canada',
    items: ['Canada'],
  },
  {
    groupLabel: 'Cape Verde',
    items: ['Cape Verde'],
  },
  {
    groupLabel: 'Cayman Islands',
    items: ['Cayman Islands'],
  },
  {
    groupLabel: 'Central African Republic',
    items: ['Central African Republic'],
  },
  {
    groupLabel: 'Chad',
    items: ['Chad'],
  },
  {
    groupLabel: 'Chile',
    items: ['Chile'],
  },
  {
    groupLabel: 'China',
    items: ['China'],
  },
  {
    groupLabel: 'Christmas Island',
    items: ['Christmas Island'],
  },
  {
    groupLabel: 'Cocos (Keeling) Islands',
    items: ['Cocos (Keeling) Islands'],
  },
  {
    groupLabel: 'Colombia',
    items: ['Colombia'],
  },
  {
    groupLabel: 'Comoros',
    items: ['Comoros'],
  },
  {
    groupLabel: 'Congo',
    items: ['Congo'],
  },
  {
    groupLabel: 'Congo, The Democratic Republic of the',
    items: ['Congo, The Democratic Republic of the'],
  },
  {
    groupLabel: 'Cook Islands',
    items: ['Cook Islands'],
  },
  {
    groupLabel: 'Costa Rica',
    items: ['Costa Rica'],
  },
  {
    groupLabel: "Cote D'Ivoire",
    items: ["Cote D'Ivoire"],
  },
  {
    groupLabel: 'Croatia',
    items: ['Croatia'],
  },
  {
    groupLabel: 'Cuba',
    items: ['Cuba'],
  },
  {
    groupLabel: 'Cyprus',
    items: ['Cyprus'],
  },
  {
    groupLabel: 'Czech Republic',
    items: ['Czech Republic'],
  },
  {
    groupLabel: 'Denmark',
    items: ['Denmark'],
  },
  {
    groupLabel: 'Djibouti',
    items: ['Djibouti'],
  },
  {
    groupLabel: 'Dominica',
    items: ['Dominica'],
  },
  {
    groupLabel: 'Dominican Republic',
    items: ['Dominican Republic'],
  },
  {
    groupLabel: 'Ecuador',
    items: ['Ecuador'],
  },
  {
    groupLabel: 'Egypt',
    items: ['Egypt'],
  },
  {
    groupLabel: 'El Salvador',
    items: ['El Salvador'],
  },
  {
    groupLabel: 'Equatorial Guinea',
    items: ['Equatorial Guinea'],
  },
  {
    groupLabel: 'Eritrea',
    items: ['Eritrea'],
  },
  {
    groupLabel: 'Estonia',
    items: ['Estonia'],
  },
  {
    groupLabel: 'Ethiopia',
    items: ['Ethiopia'],
  },
  {
    groupLabel: 'Falkland Islands (Malvinas)',
    items: ['Falkland Islands (Malvinas)'],
  },
  {
    groupLabel: 'Faroe Islands',
    items: ['Faroe Islands'],
  },
  {
    groupLabel: 'Fiji',
    items: ['Fiji'],
  },
  {
    groupLabel: 'Finland',
    items: ['Finland'],
  },
  {
    groupLabel: 'France',
    items: ['France'],
  },
  {
    groupLabel: 'French Guiana',
    items: ['French Guiana'],
  },
  {
    groupLabel: 'French Polynesia',
    items: ['French Polynesia'],
  },
  {
    groupLabel: 'French Southern Territories',
    items: ['French Southern Territories'],
  },
  {
    groupLabel: 'Gabon',
    items: ['Gabon'],
  },
  {
    groupLabel: 'Gambia',
    items: ['Gambia'],
  },
  {
    groupLabel: 'Georgia',
    items: ['Georgia'],
  },
  {
    groupLabel: 'Germany',
    items: ['Germany'],
  },
  {
    groupLabel: 'Ghana',
    items: ['Ghana'],
  },
  {
    groupLabel: 'Gibraltar',
    items: ['Gibraltar'],
  },
  {
    groupLabel: 'Greece',
    items: ['Greece'],
  },
  {
    groupLabel: 'Greenland',
    items: ['Greenland'],
  },
  {
    groupLabel: 'Grenada',
    items: ['Grenada'],
  },
  {
    groupLabel: 'Guadeloupe',
    items: ['Guadeloupe'],
  },
  {
    groupLabel: 'Guam',
    items: ['Guam'],
  },
  {
    groupLabel: 'Guatemala',
    items: ['Guatemala'],
  },
  {
    groupLabel: 'Guernsey',
    items: ['Guernsey'],
  },
  {
    groupLabel: 'Guinea',
    items: ['Guinea'],
  },
  {
    groupLabel: 'Guinea-Bissau',
    items: ['Guinea-Bissau'],
  },
  {
    groupLabel: 'Guyana',
    items: ['Guyana'],
  },
  {
    groupLabel: 'Haiti',
    items: ['Haiti'],
  },
  {
    groupLabel: 'Heard Island and Mcdonald Islands',
    items: ['Heard Island and Mcdonald Islands'],
  },
  {
    groupLabel: 'Holy See (Vatican City State)',
    items: ['Holy See (Vatican City State)'],
  },
  {
    groupLabel: 'Honduras',
    items: ['Honduras'],
  },
  {
    groupLabel: 'Hong Kong',
    items: ['Hong Kong'],
  },
  {
    groupLabel: 'Hungary',
    items: ['Hungary'],
  },
  {
    groupLabel: 'Iceland',
    items: ['Iceland'],
  },
  {
    groupLabel: 'India',
    items: ['India'],
  },
  {
    groupLabel: 'Indonesia',
    items: ['Indonesia'],
  },
  {
    groupLabel: 'Iran, Islamic Republic Of',
    items: ['Iran, Islamic Republic Of'],
  },
  {
    groupLabel: 'Iraq',
    items: ['Iraq'],
  },
  {
    groupLabel: 'Ireland',
    items: ['Ireland'],
  },
  {
    groupLabel: 'Isle of Man',
    items: ['Isle of Man'],
  },
  {
    groupLabel: 'Israel',
    items: ['Israel'],
  },
  {
    groupLabel: 'Italy',
    items: ['Italy'],
  },
  {
    groupLabel: 'Jamaica',
    items: ['Jamaica'],
  },
  {
    groupLabel: 'Japan',
    items: ['Japan'],
  },
  {
    groupLabel: 'Jersey',
    items: ['Jersey'],
  },
  {
    groupLabel: 'Jordan',
    items: ['Jordan'],
  },
  {
    groupLabel: 'Kazakhstan',
    items: ['Kazakhstan'],
  },
  {
    groupLabel: 'Kenya',
    items: ['Kenya'],
  },
  {
    groupLabel: 'Kiribati',
    items: ['Kiribati'],
  },
  {
    groupLabel: "Korea, Democratic People'S Republic of",
    items: ["Korea, Democratic People'S Republic of"],
  },
  {
    groupLabel: 'Korea, Republic of',
    items: ['Korea, Republic of'],
  },
  {
    groupLabel: 'Kuwait',
    items: ['Kuwait'],
  },
  {
    groupLabel: 'Kyrgyzstan',
    items: ['Kyrgyzstan'],
  },
  {
    groupLabel: "Lao People'S Democratic Republic",
    items: ["Lao People'S Democratic Republic"],
  },
  {
    groupLabel: 'Latvia',
    items: ['Latvia'],
  },
  {
    groupLabel: 'Lebanon',
    items: ['Lebanon'],
  },
  {
    groupLabel: 'Lesotho',
    items: ['Lesotho'],
  },
  {
    groupLabel: 'Liberia',
    items: ['Liberia'],
  },
  {
    groupLabel: 'Libyan Arab Jamahiriya',
    items: ['Libyan Arab Jamahiriya'],
  },
  {
    groupLabel: 'Liechtenstein',
    items: ['Liechtenstein'],
  },
  {
    groupLabel: 'Lithuania',
    items: ['Lithuania'],
  },
  {
    groupLabel: 'Luxembourg',
    items: ['Luxembourg'],
  },
  {
    groupLabel: 'Macao',
    items: ['Macao'],
  },
  {
    groupLabel: 'Macedonia, The Former Yugoslav Republic of',
    items: ['Macedonia, The Former Yugoslav Republic of'],
  },
  {
    groupLabel: 'Madagascar',
    items: ['Madagascar'],
  },
  {
    groupLabel: 'Malawi',
    items: ['Malawi'],
  },
  {
    groupLabel: 'Malaysia',
    items: ['Malaysia'],
  },
  {
    groupLabel: 'Maldives',
    items: ['Maldives'],
  },
  {
    groupLabel: 'Mali',
    items: ['Mali'],
  },
  {
    groupLabel: 'Malta',
    items: ['Malta'],
  },
  {
    groupLabel: 'Marshall Islands',
    items: ['Marshall Islands'],
  },
  {
    groupLabel: 'Martinique',
    items: ['Martinique'],
  },
  {
    groupLabel: 'Mauritania',
    items: ['Mauritania'],
  },
  {
    groupLabel: 'Mauritius',
    items: ['Mauritius'],
  },
  {
    groupLabel: 'Mayotte',
    items: ['Mayotte'],
  },
  {
    groupLabel: 'Mexico',
    items: ['Mexico'],
  },
  {
    groupLabel: 'Micronesia, Federated States of',
    items: ['Micronesia, Federated States of'],
  },
  {
    groupLabel: 'Moldova, Republic of',
    items: ['Moldova, Republic of'],
  },
  {
    groupLabel: 'Monaco',
    items: ['Monaco'],
  },
  {
    groupLabel: 'Mongolia',
    items: ['Mongolia'],
  },
  {
    groupLabel: 'Montserrat',
    items: ['Montserrat'],
  },
  {
    groupLabel: 'Morocco',
    items: ['Morocco'],
  },
  {
    groupLabel: 'Mozambique',
    items: ['Mozambique'],
  },
  {
    groupLabel: 'Myanmar',
    items: ['Myanmar'],
  },
  {
    groupLabel: 'Namibia',
    items: ['Namibia'],
  },
  {
    groupLabel: 'Nauru',
    items: ['Nauru'],
  },
  {
    groupLabel: 'Nepal',
    items: ['Nepal'],
  },
  {
    groupLabel: 'Netherlands',
    items: ['Netherlands'],
  },
  {
    groupLabel: 'Netherlands Antilles',
    items: ['Netherlands Antilles'],
  },
  {
    groupLabel: 'New Caledonia',
    items: ['New Caledonia'],
  },
  {
    groupLabel: 'New Zealand',
    items: ['New Zealand'],
  },
  {
    groupLabel: 'Nicaragua',
    items: ['Nicaragua'],
  },
  {
    groupLabel: 'Niger',
    items: ['Niger'],
  },
  {
    groupLabel: 'Nigeria',
    items: ['Nigeria'],
  },
  {
    groupLabel: 'Niue',
    items: ['Niue'],
  },
  {
    groupLabel: 'Norfolk Island',
    items: ['Norfolk Island'],
  },
  {
    groupLabel: 'Northern Mariana Islands',
    items: ['Northern Mariana Islands'],
  },
  {
    groupLabel: 'Norway',
    items: ['Norway'],
  },
  {
    groupLabel: 'Oman',
    items: ['Oman'],
  },
  {
    groupLabel: 'Pakistan',
    items: ['Pakistan'],
  },
  {
    groupLabel: 'Palau',
    items: ['Palau'],
  },
  {
    groupLabel: 'Palestinian Territory, Occupied',
    items: ['Palestinian Territory, Occupied'],
  },
  {
    groupLabel: 'Panama',
    items: ['Panama'],
  },
  {
    groupLabel: 'Papua New Guinea',
    items: ['Papua New Guinea'],
  },
  {
    groupLabel: 'Paraguay',
    items: ['Paraguay'],
  },
  {
    groupLabel: 'Peru',
    items: ['Peru'],
  },
  {
    groupLabel: 'Philippines',
    items: ['Philippines'],
  },
  {
    groupLabel: 'Pitcairn',
    items: ['Pitcairn'],
  },
  {
    groupLabel: 'Poland',
    items: ['Poland'],
  },
  {
    groupLabel: 'Portugal',
    items: ['Portugal'],
  },
  {
    groupLabel: 'Puerto Rico',
    items: ['Puerto Rico'],
  },
  {
    groupLabel: 'Qatar',
    items: ['Qatar'],
  },
  {
    groupLabel: 'Reunion',
    items: ['Reunion'],
  },
  {
    groupLabel: 'Romania',
    items: ['Romania'],
  },
  {
    groupLabel: 'Russian Federation',
    items: ['Russian Federation'],
  },
  {
    groupLabel: 'RWANDA',
    items: ['RWANDA'],
  },
  {
    groupLabel: 'Saint Helena',
    items: ['Saint Helena'],
  },
  {
    groupLabel: 'Saint Kitts and Nevis',
    items: ['Saint Kitts and Nevis'],
  },
  {
    groupLabel: 'Saint Lucia',
    items: ['Saint Lucia'],
  },
  {
    groupLabel: 'Saint Pierre and Miquelon',
    items: ['Saint Pierre and Miquelon'],
  },
  {
    groupLabel: 'Saint Vincent and the Grenadines',
    items: ['Saint Vincent and the Grenadines'],
  },
  {
    groupLabel: 'Samoa',
    items: ['Samoa'],
  },
  {
    groupLabel: 'San Marino',
    items: ['San Marino'],
  },
  {
    groupLabel: 'Sao Tome and Principe',
    items: ['Sao Tome and Principe'],
  },
  {
    groupLabel: 'Saudi Arabia',
    items: ['Saudi Arabia'],
  },
  {
    groupLabel: 'Senegal',
    items: ['Senegal'],
  },
  {
    groupLabel: 'Serbia and Montenegro',
    items: ['Serbia and Montenegro'],
  },
  {
    groupLabel: 'Seychelles',
    items: ['Seychelles'],
  },
  {
    groupLabel: 'Sierra Leone',
    items: ['Sierra Leone'],
  },
  {
    groupLabel: 'Singapore',
    items: ['Singapore'],
  },
  {
    groupLabel: 'Slovakia',
    items: ['Slovakia'],
  },
  {
    groupLabel: 'Slovenia',
    items: ['Slovenia'],
  },
  {
    groupLabel: 'Solomon Islands',
    items: ['Solomon Islands'],
  },
  {
    groupLabel: 'Somalia',
    items: ['Somalia'],
  },
  {
    groupLabel: 'South Africa',
    items: ['South Africa'],
  },
  {
    groupLabel: 'South Georgia and the South Sandwich Islands',
    items: ['South Georgia and the South Sandwich Islands'],
  },
  {
    groupLabel: 'Spain',
    items: ['Spain'],
  },
  {
    groupLabel: 'Sri Lanka',
    items: ['Sri Lanka'],
  },
  {
    groupLabel: 'Sudan',
    items: ['Sudan'],
  },
  {
    groupLabel: 'Suriname',
    items: ['Suriname'],
  },
  {
    groupLabel: 'Svalbard and Jan Mayen',
    items: ['Svalbard and Jan Mayen'],
  },
  {
    groupLabel: 'Swaziland',
    items: ['Swaziland'],
  },
  {
    groupLabel: 'Sweden',
    items: ['Sweden'],
  },
  {
    groupLabel: 'Switzerland',
    items: ['Switzerland'],
  },
  {
    groupLabel: 'Syrian Arab Republic',
    items: ['Syrian Arab Republic'],
  },
  {
    groupLabel: 'Taiwan, Province of China',
    items: ['Taiwan, Province of China'],
  },
  {
    groupLabel: 'Tajikistan',
    items: ['Tajikistan'],
  },
  {
    groupLabel: 'Tanzania, United Republic of',
    items: ['Tanzania, United Republic of'],
  },
  {
    groupLabel: 'Thailand',
    items: ['Thailand'],
  },
  {
    groupLabel: 'Timor-Leste',
    items: ['Timor-Leste'],
  },
  {
    groupLabel: 'Togo',
    items: ['Togo'],
  },
  {
    groupLabel: 'Tokelau',
    items: ['Tokelau'],
  },
  {
    groupLabel: 'Tonga',
    items: ['Tonga'],
  },
  {
    groupLabel: 'Trinidad and Tobago',
    items: ['Trinidad and Tobago'],
  },
  {
    groupLabel: 'Tunisia',
    items: ['Tunisia'],
  },
  {
    groupLabel: 'Turkey',
    items: ['Turkey'],
  },
  {
    groupLabel: 'Turkmenistan',
    items: ['Turkmenistan'],
  },
  {
    groupLabel: 'Turks and Caicos Islands',
    items: ['Turks and Caicos Islands'],
  },
  {
    groupLabel: 'Tuvalu',
    items: ['Tuvalu'],
  },
  {
    groupLabel: 'Uganda',
    items: ['Uganda'],
  },
  {
    groupLabel: 'Ukraine',
    items: ['Ukraine'],
  },
  {
    groupLabel: 'United Arab Emirates',
    items: ['United Arab Emirates'],
  },
  {
    groupLabel: 'United Kingdom',
    items: ['United Kingdom', 'England', 'Scotland', 'Wales', 'Northern Ireland'],
  },
  {
    groupLabel: 'United States',
    items: ['United States'],
  },
  {
    groupLabel: 'United States Minor Outlying Islands',
    items: ['United States Minor Outlying Islands'],
  },
  {
    groupLabel: 'Uruguay',
    items: ['Uruguay'],
  },
  {
    groupLabel: 'Uzbekistan',
    items: ['Uzbekistan'],
  },
  {
    groupLabel: 'Vanuatu',
    items: ['Vanuatu'],
  },
  {
    groupLabel: 'Venezuela',
    items: ['Venezuela'],
  },
  {
    groupLabel: 'Viet Nam',
    items: ['Viet Nam'],
  },
  {
    groupLabel: 'Virgin Islands, British',
    items: ['Virgin Islands, British'],
  },
  {
    groupLabel: 'Virgin Islands, U.S.',
    items: ['Virgin Islands, U.S.'],
  },
  {
    groupLabel: 'Wallis and Futuna',
    items: ['Wallis and Futuna'],
  },
  {
    groupLabel: 'Western Sahara',
    items: ['Western Sahara'],
  },
  {
    groupLabel: 'Yemen',
    items: ['Yemen'],
  },
  {
    groupLabel: 'Zambia',
    items: ['Zambia'],
  },
  {
    groupLabel: 'Zimbabwe',
    items: ['Zimbabwe'],
  },
];

export const options = testCountrySelect.map((op1) => ({
  label: op1.groupLabel,
  options: op1.items.map((op) => {
    return {
      label: op,
      value: op,
    };
  }),
}));

export const returnAppropriateInputValue = (selectedOption) => {
  if (typeof selectedOption === 'string') {
    return selectedOption;
  } else if (selectedOption?.name === 'Select') {
    return '';
  } else if (Object.keys(selectedOption)?.length === 0) {
    return '';
  } else {
    return selectedOption.value;
  }
};

export const shouldShowInputField = (selectedOption, AllMenu) => {
  const AllMenuValue = AllMenu?.map((menu) => menu.value);
  if (typeof selectedOption == 'string') {
    return !AllMenuValue?.include?.(selectedOption);
  } else if (selectedOption?.value == null) {
    return false;
  } else if (!AllMenuValue.includes(selectedOption?.value)) {
    return true;
  }
  return false;
};

export const returnRightDefaultOption = (selectedOption, AllMenu) => {
  if (shouldShowInputField(selectedOption, AllMenu)) {
    return AllMenu[AllMenu.length - 1];
  } else if (selectedOption?.value == null) {
    return AllMenu?.[0];
  }
  return selectedOption;
};

export const returnBackendCompatiblePayloadOfDemogaphic = (input) => {
  if (input?.value === 'select') {
    return '';
  } else {
    if (typeof input === 'string') {
      return { name: input, value: input };
    }
    return input;
  }
};

export const COMMON_TITLE_NAME = () => {
  const org_information = getLocalStorageItem('org_information');
  if (org_information) {
    return JSON.parse(localStorage.getItem('org_information'))?.name;
  }
  return 'Innovation Collective Community';
};

export const LOCATION_LIST = [
  { id: 1, name: 'All UK areas' },
  { id: 2, name: 'East Midlands' },
  { id: 3, name: 'East of England' },
  { id: 4, name: 'Europe' },
  { id: 5, name: 'International' },
  { id: 6, name: 'Ireland' },
  { id: 7, name: 'London' },
  { id: 8, name: 'North East' },
  { id: 9, name: 'North West' },
  { id: 10, name: 'Scotland' },
  { id: 11, name: 'South East' },
  { id: 12, name: 'South West' },
  { id: 13, name: 'Wales' },
  { id: 14, name: 'West Midlands' },
  { id: 15, name: 'Yorkshire and The Humber' },
];
export const PREFILL_FIELD_NAME = {
  Email: 'Email',
  Gender: 'Gender',
  Phone: 'Phone',
  'Date Of Birth': 'Date Of Birth',
  'First Name': 'First Name',
  'Last Name': 'Last Name',
  Location: 'Location',
  About: 'About',
  'Job Position': 'Job Position',
};

export const returnKeyOfLabelFromFormIoFormJson = (formJson, label) => {
  if (formJson?.components?.[0]?.type === 'tabs') {
    const tabs = formJson?.components?.[0].components;
    for (let i = 0; i < tabs?.length; i++) {
      for (let j = 0; j < tabs[i]?.components?.length; j++) {
        if (tabs[i]?.components?.[j]?.label.indexOf(label) !== -1) {
          return {
            type: tabs[i]?.components?.[j]?.key,
          };
        }
      }
    }
  } else {
    for (let i = 0; i < formJson?.components?.length; i++) {
      if (formJson?.components?.[i].label.indexOf(label) !== -1) {
        return {
          type: formJson?.components?.[i].key,
        };
      }
    }
  }
};
