import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { handleGtmScriptAdd } from '../../utils/helper';
import { Cookies } from 'react-cookie-consent';

export default function CookiePopup() {
  const handleDeclineCookie = () => {
    Cookies.remove('_ga');
    Cookies.remove('_gat');
    Cookies.remove('_gid');
  };
  return (
    <CookieConsent
      location='bottom'
      enableDeclineButton
      buttonText='Accept All'
      declineButtonText='Reject non essential'
      buttonWrapperClasses='flex flex-col justify-center h-full'
      buttonClasses='bg-black px-4 py-1 leading-8 font-semibold rounded-md border border-black hover:bg-white hover:text-black text-black transition-all duration-200'
      declineButtonClasses='bg-black px-4 py-1 leading-8 font-semibold rounded-md border border-black hover:bg-white hover:text-black text-white transition-all duration-200'
      cookieName='ww-cookie'
      buttonStyle={{ backgroundColor: 'white', color: 'black' }}
      declineButtonStyle={{ backgroundColor: 'black', color: 'white' }}
      onAccept={handleGtmScriptAdd}
      onDecline={handleDeclineCookie}
    >
      <div div className='space-y-2 p-8'>
        <h2 className='text-2xl font-medium'> Cookies on My Innovation Collective</h2>
        <p className='font-semibold text-base'>We use cookies to give you the best online experience.</p>
        <p className='text-sm font-normal'>Select 'Accept all' to agree to all cookies.</p>
        <p className='text-sm font-normal'>
          Some cookies are essential. Others can be controlled in your cookie preferences.
        </p>
      </div>
    </CookieConsent>
  );
}
