import React, { useContext, useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  ChatContext,
  UserDetailsContext,
  cleanLocalStorage,
  getLocalStorageItem,
  getZitadelToken,
  userData,
} from '../utils/helper';
import Header from '../layout/Header';
import LeftSidebar from '../layout/LeftSidebar';
import RightSidebar from '../layout/RightSidebar';
import { ReactComponent as MessageIcon } from '../assets/images/message.svg';
import { Api } from '../api';
import CookiePopup from '../component/common/CookiePopup';
import WWFooter from '../container/TermsAndCondition/WwFooter';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();

  const pathname = history?.location?.pathname?.split('/');

  const { isAccessible, setIsAccessible, getScheduleActivityCounts, setAllNetworks } = useContext(UserDetailsContext);
  const { isMobileDevice } = useContext(ChatContext);

  const userD = userData();

  useEffect(() => {
    let steps = userD?.is_step;
    const stepKeys = Object.keys(steps || {});

    const allStepsCompleted = stepKeys.every((step) => steps?.[step]);

    if (getLocalStorageItem('token') && getZitadelToken() && allStepsCompleted) {
      setIsAccessible(true);
      const redirectTo = localStorage.getItem('redirect_url');
      localStorage.removeItem('redirect_url');
      if (redirectTo) {
        history.push(redirectTo == 'null' ? '/home' : history.push(redirectTo));
      }
    } else {
      setIsAccessible(false);
      cleanLocalStorage();
      localStorage.setItem('redirect_url', window.location.pathname);
      history.push('/');
    }
    // eslint-disable-next-line
  }, [history]);

  const getAllNetworksList = () => {
    if (isAccessible) {
      Api.getNetworksList().then((response) => {
        if (response?.data?.meta?.code === 200) {
          const tempNetworks = response?.data?.detail?.network_list?.filter(
            (i) => i.name !== 'Shuri Network' && i.id !== 1,
          );
          setAllNetworks(tempNetworks);
        }
      });
    }
  };

  useEffect(() => {
    if (isAccessible) {
      getScheduleActivityCounts();
      getAllNetworksList();
    }
    // eslint-disable-next-line
  }, [isAccessible]);

  useEffect(() => {
    if (isMobileDevice && pathname[1] !== 'chats' && localStorage.getItem('temp_user_chat')) {
      history.push('/chats');
    }
  }, [isMobileDevice]);

  return (
    <div className='!mx-auto !max-w-[1920px] !w-full'>
      <CookiePopup />
      {isAccessible && <Header />}

      <div id='main' className='!main'>
        <div className='!h-full'>
          {isAccessible && (
            <div className='!h-full !flex !w-full !px-4 sm:!px-6 md:!p-[30px] !pt-[20px] md:!pt-[30px] !justify-between !items-start'>
              <aside className='!sticky !top-[98px] !hidden lg:!block !w-full !max-w-[265px] !pr-[40px]'>
                <LeftSidebar />
              </aside>
              <main className='!h-full !w-full lg:!w-[calc(100%-250px)] xl:!w-[calc(100%-650px)]'>
                <Route {...rest} render={(props) => <Component {...props} />} />
              </main>

              <aside className='!sticky !hidden !top-[98px] !max-w-[380px] !w-full !shrink-0 xl:!block z-10'>
                <RightSidebar />
              </aside>

              {isMobileDevice && pathname[1] !== 'chats' && (
                <div
                  className='!rounded-full hover:!h-12 hover:!w-12 !h-11 !w-11 !transition-all !bg-hexitime-primary !flex !justify-center !items-center !fixed !bottom-5 !right-5 !cursor-pointer'
                  onClick={() => history.push('/chats')}
                >
                  <MessageIcon className='!text-white !h-6 !w-6' />
                </div>
              )}
            </div>
          )}
        </div>
        <div className='!border-t'>
          <WWFooter />
        </div>
      </div>
    </div>
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.object,
  rest: PropTypes.object,
};

export default PrivateRoute;
